import React, { memo } from 'react'
import { Box, Container, styled } from '@mui/material'

import TextAndImage, {
  TextAndImageProps,
} from 'components/molecules/textAndImage/TextAndImage'

interface Props {
  textAndImageProps: TextAndImageProps
  bgColor: string
}

const TextAndImageContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
}))

const TextAndImageSection = ({ textAndImageProps, bgColor }: Props) => {
  return (
    <Box component="section" bgcolor={bgColor} py={{ xs: 0, lg: 6, xl: 9 }}>
      <TextAndImageContainer maxWidth="xl">
        <TextAndImage {...textAndImageProps} />
      </TextAndImageContainer>
    </Box>
  )
}

export default memo(TextAndImageSection)
