import React from 'react'
import { graphql } from 'gatsby'
import { Grid, Container, Typography } from '@mui/material'

// @ts-ignore
import { HomepageQuery, DatoCmsIconCardLink } from '__generated__/gatsby-types'

// @ts-ignore
import createNewsEventsCollection from 'helpers/news-events-collection'
import Layout from 'components/organisms/global/Layout'
import CurveMask from 'components/atoms/CurveMask'
import ImageHero from 'components/organisms/hero/ImageHero'
import Sun from 'components/atoms/Sun'
import SunBoxContainer from 'components/atoms/SunBoxContainer'
import TextAndImageSection from 'components/organisms/sections/TextAndImageSecion'
import SwiperLandingSection from 'components/organisms/sections/SwiperLandingSection'
import GridLandingSection from 'components/organisms/sections/GridLandingSection'

interface Props {
  data: HomepageQuery
}

const IndexPage = ({ data }: Props) => {
  const d = data.datoCmsHomepage
  const hero = d.hero[0]

  const newsEvents = createNewsEventsCollection({
    news: data.allDatoCmsNewsArticle.edges,
    events: data.allDatoCmsEvent.edges,
    limit: 6,
  })

  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      <ImageHero
        keyId={hero.originalId}
        imageLandscape={hero.imageLandscape.gatsbyImageData}
        imagePortrait={hero.imagePortrait.gatsbyImageData}
        heading={hero.mainHeadingNode.childMarkdownRemark.html}
        button={d.heroButton[0]}
        moreButtonLabel={d.exploreButtonLabel}
      />
      <SunBoxContainer
        component="section"
        py={{ xs: 6, sm: 8.5, md: 9, lg: 9.5, xl: 10 }}
      >
        <Sun size="small" position="top-left" />
        <Container>
          <Typography component="div" variant="leadText" textAlign="center">
            {d.leadText}
          </Typography>
        </Container>
      </SunBoxContainer>

      <GridLandingSection
        heading={d.landingSection[0].heading}
        cards={d.landingSection[0].iconLinks}
        sectionBgColor="lightGrey"
        layout="curve"
      />
      <TextAndImageSection
        bgColor="common.white"
        textAndImageProps={{
          layout: 'full-section',
          panelBgColor: 'primary_blue',
          heading: d.contentPanel[0].heading,
          text: d.contentPanel[0].text,
          panel: d.contentPanel[0].displayPanel,
          image: d.contentPanel[0].image.gatsbyImageData,
          imageLandscape: d.contentPanel[0].imageLandscape.gatsbyImageData,
          button: d.contentPanel[0].button,
          callToAction: d.contentPanel[0].callToAction,
          buttonProps: { color: 'secondary' },
          imageRightSide: d.contentPanel[0].displayImageRightSide,
        }}
      />
      <SwiperLandingSection
        layout="curve_sun"
        heading={d.newsEventsSectionHeading}
        cards={newsEvents}
        sectionBgColor="lightGrey"
        containerBgProps={{
          bgcolor: { xs: 'primary.main', lg: 'common.white' },
        }}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    datoCmsHomepage {
      hero {
        originalId
        mainHeadingNode {
          childMarkdownRemark {
            html
          }
        }
        imageLandscape: heroImage {
          gatsbyImageData(
            width: 1600
            layout: FULL_WIDTH
            imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
          )
        }
        imagePortrait: heroImage {
          gatsbyImageData(
            width: 900
            layout: FULL_WIDTH
            imgixParams: { ar: "1:2.2", fit: "crop", crop: "focalpoint" }
          )
        }
      }
      exploreButtonLabel
      heroButton {
        ...button
      }
      originalId
      leadText
      landingSection {
        heading
        iconLinks {
          originalId
          buttonLabel
          link {
            model {
              apiKey
            }
            slug
            cardContent {
              heading
              text
            }
            heroImage {
              gatsbyImageData(
                width: 942
                layout: CONSTRAINED
                imgixParams: { ar: "5:3", fit: "crop", crop: "focalpoint" }
              )
            }
            heroImageWide: heroImage {
              gatsbyImageData(
                width: 1100
                layout: CONSTRAINED
                imgixParams: { ar: "9:3", fit: "crop", crop: "focalpoint" }
              )
            }
          }
          icon {
            slug
          }
        }
      }
      contentPanel {
        heading
        image {
          gatsbyImageData(
            width: 984
            layout: FULL_WIDTH
            imgixParams: { ar: "5:3", fit: "crop", crop: "focalpoint" }
          )
        }
        imageLandscape: image {
          gatsbyImageData(
            width: 924
            layout: CONSTRAINED
            imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
          )
        }
        displayPanel
        callToAction {
          ... on DatoCmsDocument {
            ...contentDocument
          }
          ... on DatoCmsButton {
            ...button
          }
          ... on DatoCmsButtonGroup {
            ...contentButtonGroup
          }
        }
        displayImageRightSide
        text
      }
      newsEventsSectionHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsEvent(limit: 6) {
      edges {
        node {
          ...eventCard
        }
      }
    }
    allDatoCmsNewsArticle(limit: 6) {
      edges {
        node {
          ...newsCard
        }
      }
    }
    datoCmsGlobalContent {
      moreNewsEventsHeading
    }
  }
`
